@charset "UTF-8";
body {
  font-size: 1.6rem;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "游ゴシック体", "Yu Gothic", YuGothic, "MS Pゴシック", "MS PGothic", sans-serif;
  font-style: normal;
  line-height: 1.8;
  font-weight: 400;
  color: #333;
}

/*==================================================
ふわっ
===================================*/
/* その場で */
.fadeIn {
  animation-name: fadeInAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeInAnime {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 下から */
.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 上から */
.fadeDown {
  animation-name: fadeDownAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeDownAnime {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 左から */
.fadeLeft {
  animation-name: fadeLeftAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeLeftAnime {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* 右から */
.fadeRight {
  animation-name: fadeRightAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeRightAnime {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.fadeInTrigger,
.fadeUpTrigger,
.fadeDownTrigger,
.fadeLeftTrigger,
.fadeRightTrigger {
  opacity: 0;
}

/*==================================================
パタッ
===================================*/
/* 下へ */
.flipDown {
  animation-name: flipDownAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes flipDownAnime {
  from {
    transform: perspective(2500px) rotateX(100deg);
    opacity: 0;
  }
  to {
    transform: perspective(2500px) rotateX(0);
    opacity: 1;
  }
}

/* 左へ */
.flipLeft {
  animation-name: flipLeftAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  perspective-origin: left center;
  opacity: 0;
}

@keyframes flipLeftAnime {
  from {
    transform: perspective(600px) translate3d(0, 0, 0) rotateY(30deg);
    opacity: 0;
  }
  to {
    transform: perspective(600px) translate3d(0, 0, 0) rotateY(0deg);
    opacity: 1;
  }
}

/* 左上へ */
.flipLeftTop {
  animation-name: flipLeftTopAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes flipLeftTopAnime {
  from {
    transform: translate(-20px, 80px) rotate(-15deg);
    opacity: 0;
  }
  to {
    transform: translate(0, 0) rotate(0deg);
    opacity: 1;
  }
}

/* 右へ */
.flipRight {
  animation-name: flipRightAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  perspective-origin: right center;
  opacity: 0;
}

@keyframes flipRightAnime {
  from {
    transform: perspective(600px) translate3d(0, 0, 0) rotateY(-30deg);
    opacity: 0;
  }
  to {
    transform: perspective(600px) translate3d(0, 0, 0) rotateY(0deg);
    opacity: 1;
  }
}

/* 右上へ */
.flipRightTop {
  animation-name: flipRightTopAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes flipRightTopAnime {
  from {
    transform: translate(-20px, 80px) rotate(25deg);
    opacity: 0;
  }
  to {
    transform: translate(0, 1) rotate(0deg);
    opacity: 1;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.flipDownTrigger,
.flipLeftTrigger,
.flipLeftTopTrigger,
.flipRightTrigger,
.flipRightTopTrigger {
  opacity: 0;
}

/*==================================================
くるっ
===================================*/
/* X 軸（縦へ） */
.rotateX {
  animation-name: rotateXAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes rotateXAnime {
  from {
    transform: rotateX(0);
    opacity: 0;
  }
  to {
    transform: rotateX(-360deg);
    opacity: 1;
  }
}

/*　Y軸（横へ） */
.rotateY {
  animation-name: rotateYAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes rotateYAnime {
  from {
    transform: rotateY(0);
    opacity: 0;
  }
  to {
    transform: rotateY(-360deg);
    opacity: 1;
  }
}

/* Z 軸（左へ） */
.rotateLeftZ {
  animation-name: rotateLeftZAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes rotateLeftZAnime {
  from {
    transform: rotateZ(0);
    opacity: 0;
  }
  to {
    transform: rotateZ(-360deg);
    opacity: 1;
  }
}

/*　Z 軸（右へ） */
.rotateRightZ {
  animation-name: rotateRightZAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes rotateRightZAnime {
  from {
    transform: rotateZ(0);
    opacity: 0;
  }
  to {
    transform: rotateZ(360deg);
    opacity: 1;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.rotateXTrigger,
.rotateYTrigger,
.rotateLeftZTrigger,
.rotateRightZTrigger {
  opacity: 0;
}

/*==================================================
ボンッ、ヒュッ
===================================*/
/* 拡大 */
.zoomIn {
  animation-name: zoomInAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes zoomInAnime {
  from {
    transform: scale(0.6);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* 縮小 */
.zoomOut {
  animation-name: zoomOutAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes zoomOutAnime {
  from {
    transform: scale(1.2);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.zoomInTrigger,
.zoomOutTrigger {
  opacity: 0;
}

/*==================================================
じわっ
===================================*/
/* ぼかしから出現 */
.blur {
  animation-name: blurAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes blurAnime {
  from {
    filter: blur(10px);
    transform: scale(1.02);
    opacity: 0;
  }
  to {
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.blurTrigger {
  opacity: 0;
}

/*==================================================
にゅーん
===================================*/
/* 滑らかに変形して出現 */
.smooth {
  animation-name: smoothAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  　transform-origin: left;
  opacity: 0;
}

@keyframes smoothAnime {
  from {
    transform: translate3d(0, 100%, 0) skewY(12deg);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0) skewY(0);
    opacity: 1;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.smoothTrigger {
  opacity: 0;
}

/*==================================================
スーッ（枠線が伸びて出現）
===================================*/
/*枠線が伸びて出現*/
.lineTrigger {
  position: relative;
  /* 枠線が書かれる基点*/
  opacity: 0;
}

.lineTrigger.lineanime {
  animation-name: lineAnimeBase;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes lineAnimeBase {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*上下線*/
.lineTrigger::before,
.lineTrigger::after {
  position: absolute;
  content: "";
  width: 0;
  height: 1px;
  background: #333;
  /* 枠線の色*/
}

/*左右線*/
.line2::before,
.line2::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 0;
  background: #333;
  /* 枠線の色*/
}

/*上線*/
.lineTrigger::before {
  top: 0;
  left: 0;
}

.lineTrigger.lineanime::before {
  animation: lineAnime 0.5s linear 0s forwards;
  /*表示されて0秒後に上線が0.5秒かけて表示*/
}

/*右線*/
.line2::before {
  top: 0;
  right: 0;
}

.lineTrigger.lineanime .line2::before {
  animation: lineAnime2 0.5s linear 0.5s forwards;
  /*表示されて0.5秒後に右線が0.5秒かけて表示*/
}

/*下線*/
.lineTrigger::after {
  bottom: 0;
  right: 0;
}

.lineTrigger.lineanime::after {
  animation: lineAnime 0.5s linear 1s forwards;
  /*表示されて1秒後に下線が0.5秒かけて表示*/
}

/*左線*/
.line2::after {
  bottom: 0;
  left: 0;
}

.lineTrigger.lineanime .line2::after {
  animation: lineAnime2 0.5s linear 1.5s forwards;
  /*表示されて1.5秒後に左線が0.5秒かけて表示*/
}

@keyframes lineAnime {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes lineAnime2 {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

/*枠線内側の要素*/
.lineTrigger.lineanime .lineinappear {
  animation: lineInnerAnime 0.5s linear 1.5s forwards;
  /*1.5秒後に中央のエリアが0.5秒かけて表示*/
  opacity: 0;
  /*初期値を透過0にする*/
}

@keyframes lineInnerAnime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*==================================================
シャッ（背景色が伸びて出現）
===================================*/
/*背景色が伸びて出現（共通）*/
.bgextend {
  animation-name: bgextendAnimeBase;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  position: relative;
  overflow: hidden;
  /*　はみ出た色要素を隠す　*/
  opacity: 0;
}

@keyframes bgextendAnimeBase {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*中の要素*/
.bgappear {
  animation-name: bgextendAnimeSecond;
  animation-duration: 1s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes bgextendAnimeSecond {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*左から*/
.bgLRextend::before {
  animation-name: bgLRextendAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #666;
  /*伸びる背景色の設定*/
}

@keyframes bgLRextendAnime {
  0% {
    transform-origin: left;
    transform: scaleX(0);
  }
  50% {
    transform-origin: left;
    transform: scaleX(1);
  }
  50.001% {
    transform-origin: right;
  }
  100% {
    transform-origin: right;
    transform: scaleX(0);
  }
}

/*右から*/
.bgRLextend::before {
  animation-name: bgRLextendAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #666;
  /*伸びる背景色の設定*/
}

@keyframes bgRLextendAnime {
  0% {
    transform-origin: right;
    transform: scaleX(0);
  }
  50% {
    transform-origin: right;
    transform: scaleX(1);
  }
  50.001% {
    transform-origin: left;
  }
  100% {
    transform-origin: left;
    transform: scaleX(0);
  }
}

/*下から*/
.bgDUextend::before {
  animation-name: bgDUextendAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #666;
  /*伸びる背景色の設定*/
}

@keyframes bgDUextendAnime {
  0% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
  50% {
    transform-origin: bottom;
    transform: scaleY(1);
  }
  50.001% {
    transform-origin: top;
  }
  100% {
    transform-origin: top;
    transform: scaleY(0);
  }
}

/*上から*/
.bgUDextend::before {
  animation-name: bgUDextendAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #666;
  /*伸びる背景色の設定*/
}

@keyframes bgUDextendAnime {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }
  50% {
    transform-origin: top;
    transform: scaleY(1);
  }
  50.001% {
    transform-origin: bottom;
  }
  100% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.bgappearTrigger,
.bgUDextendTrigger,
.bgDUextendTrigger,
.bgRLextendTrigger,
.bgLRextendTrigger {
  opacity: 0;
}

/*========= レイアウトのためのCSS ===============*/
.wrapper {
  overflow: hidden;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.box {
  width: 220px;
  padding: 20px;
  margin: 0 20px 20px 20px;
  background: #666;
  color: #fff;
  box-sizing: border-box;
}

.bgextend,
.lineTrigger {
  width: 220px;
  padding: 20px;
  margin: 0 20px 20px 20px;
  box-sizing: border-box;
}

/*==================================================
アニメーション設定
===================================*/
/* アニメーションの回数を決めるCSS*/
.count2 {
  animation-iteration-count: 2;
  /*この数字を必要回数分に変更*/
}

.countinfinite {
  animation-iteration-count: infinite;
  /*無限ループ*/
}

/* アニメーションスタートの遅延時間を決めるCSS*/
.delay-time01 {
  animation-delay: 0.1s;
}

.delay-time02 {
  animation-delay: 0.2s;
}

.delay-time03 {
  animation-delay: 0.3s;
}

.delay-time04 {
  animation-delay: 0.4s;
}

.delay-time04._sp {
  animation-delay: 0.4s;
}

@media (max-width: 767px) {
  .delay-time01 {
    animation-delay: 0s;
  }
  .delay-time02 {
    animation-delay: 0s;
  }
  .delay-time04 {
    animation-delay: 0s;
  }
}

.delay-time05 {
  animation-delay: 0.5s;
}

.delay-time1 {
  animation-delay: 1s;
}

.delay-time15 {
  animation-delay: 1.5s;
}

.delay-time2 {
  animation-delay: 2s;
}

.delay-time25 {
  animation-delay: 2.5s;
}

/* アニメーション自体が変化する時間を決めるCSS*/
.change-time05 {
  animation-duration: 0.5s;
}

.change-time1 {
  animation-duration: 1s;
}

.change-time15 {
  animation-duration: 1.5s;
}

.change-time2 {
  animation-duration: 2s;
}

.change-time25 {
  animation-duration: 2.5s;
}

[data-target] {
  cursor: pointer;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  overflow-wrap: break-word;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  letter-spacing: 0.03em;
  background-size: contain;
  background-repeat: repeat;
  background-position: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  font-feature-settings: "palt";
  min-width: 1228px;
}

body[data-loading="true"] {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 767px) {
  body {
    font-size: 1.4rem;
    min-width: unset;
  }
}

ul,
li {
  list-style: none;
}

article,
aside,
footer,
header,
nav,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: top;
}

video {
  max-width: 100%;
  height: auto;
}

code,
kbd,
pre,
samp {
  font-size: inherit;
}

ul,
ol {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
}

th,
td {
  padding: 4px 6px;
  text-align: left;
  vertical-align: top;
}

blockquote,
q {
  quotes: none;
}

blockquote::after,
blockquote::before,
q::after,
q::before {
  content: "";
  content: none;
}

address {
  font-style: normal;
}

form {
  margin: 0;
  padding: 0;
}

form button {
  width: auto;
  display: inline-block;
  padding: 0.6em 2em;
  margin: 0;
  background-image: none;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 0;
  font-size: 1.6rem;
  cursor: pointer;
  -webkit-appearance: none;
  transition: all 0.3s ease 0s;
}

form button:hover, form button:focus {
  outline: none;
}

form button:hover {
  opacity: 0.7;
}

form button ::-moz-focus-inner {
  padding: 0;
  border: none;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  border: none;
  background-image: none;
  font-family: inherit;
  font-size: 1.6rem;
  -webkit-appearance: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #b2b2b2;
  background: #fff;
  border-radius: 3px;
}

@media (max-width: 767px) {
  input,
  textarea {
    width: 100%;
  }
}

textarea:hover, textarea:focus {
  outline: none;
}

textarea:focus {
  outline: none;
  box-shadow: none;
}

textarea ::-moz-focus-inner {
  padding: 0;
  border: none;
}

input:focus {
  outline: none;
  box-shadow: none;
}

input[type="submit"], input[type="button"] {
  width: auto;
  display: block;
  padding: 0;
  margin: 0;
  background: transparent;
  background-image: none;
  text-decoration: none;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  cursor: pointer;
  -webkit-appearance: none;
  transition: all 0.2s ease 0s;
  border-radius: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: #f6921e;
  font-weight: bold;
  font-size: 1.8rem;
}

input[type="submit"]:hover, input[type="submit"]:focus, input[type="button"]:hover, input[type="button"]:focus {
  opacity: 0.7;
}

input[type="submit"] ::-moz-focus-inner, input[type="button"] ::-moz-focus-inner {
  padding: 0;
  border: none;
}

@media (max-width: 767px) {
  input[type="submit"], input[type="button"] {
    font-size: 1.6rem;
  }
}

.inner {
  margin: 0 auto;
  padding: 0;
  max-width: 100%;
  width: 1180px;
  height: inherit;
  position: relative;
  min-width: 1180px;
}

@media (max-width: 1228px) {
  .inner {
    padding-right: 24px;
    padding-left: 24px;
    max-width: 1080px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .inner {
    padding-right: 16px;
    padding-left: 16px;
    min-width: auto;
  }
}

.mv._sub {
  height: 667px;
  position: relative;
}

.mv._sub._biz {
  background-image: url(../images/biz/mv.jpg);
  background-position-x: center;
  background-size: cover;
}

.mv._sub._works {
  background-image: url(../images/work/mv.jpg);
  background-position-x: center;
  background-size: cover;
}

.mv._sub._recruit {
  background-image: url(../images/recruit/mv.jpg);
  background-position-x: center;
  background-size: cover;
}

.mv._sub._outline {
  background-image: url(../images/outline/mv.jpg);
  background-position-x: center;
  background-size: cover;
}

.mv._sub._contact {
  background-image: url(../images/contact/mv.jpg);
  background-position-x: center;
  background-size: cover;
}

.mv._sub:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

@media (max-width: 767px) {
  .mv._sub {
    height: calc(100vw + 30px);
  }
}

.sub-mv-ttl {
  font-family: Avenir, acumin-pro, sans-serif;
  font-size: 7rem;
  text-align: center;
  color: #9cd4cc;
  position: relative;
  z-index: 1;
  line-height: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  letter-spacing: 8px;
}

.sub-mv-ttl span {
  color: #fff;
  display: block;
  font-size: 3rem;
  margin-top: 16px;
}

@media (max-width: 767px) {
  .sub-mv-ttl {
    font-size: 4rem;
    letter-spacing: 4px;
  }
  .sub-mv-ttl span {
    font-size: 1.8rem;
  }
}

.btn {
  background: #000;
  transition: 0.2s;
  font-size: 2.4rem;
  color: #fff;
  position: relative;
  width: 180px;
  height: 60px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.btn:hover {
  opacity: 0.7;
}

.btn .arrow {
  position: relative;
  width: 22px;
  height: 2px;
  background: #9cd4cc;
  margin-left: 12px;
}

.btn .arrow::after {
  content: "";
  position: absolute;
  vertical-align: middle;
  right: 0;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  border-top: 2px solid #9cd4cc;
  border-right: 2px solid #9cd4cc;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: auto;
}

.head {
  padding: 120px 0 0;
  text-align: center;
  overflow: hidden;
}

@media (max-width: 767px) {
  .head {
    padding: 60px 0 0;
  }
}

.sec-ttl {
  color: #9cd4cc;
  font-size: 5rem;
  font-family: "ヒラギノ丸ゴ Pro", tbudrgothic-std, sans-serif;
}

@media (max-width: 767px) {
  .sec-ttl {
    font-size: 3rem;
  }
}

.head-txt {
  margin: 60px auto 0;
  font-size: 2.4rem;
}

@media (max-width: 767px) {
  .head-txt {
    margin-top: 32px;
    font-size: 1.8rem;
  }
}

.section {
  padding: 100px 0;
}

@media (max-width: 767px) {
  .section {
    padding: 60px 0;
  }
}

.tel-contact {
  font-size: 2.4rem;
  text-align: center;
  margin-top: 40px;
}

@media (max-width: 767px) {
  .tel-contact {
    font-size: 1.8rem;
  }
}

.tel-contact a {
  display: inline-block;
  transition: 0.2s;
}

.tel-contact a:hover {
  opacity: 0.7;
}

.drawer {
  z-index: 40;
  height: 100%;
}

@media (max-width: 767px) {
  .drawer {
    width: 64px;
    height: 64px;
    position: fixed;
    right: 64px;
    top: 0;
  }
}

.drawer-close {
  display: none;
  position: fixed;
  z-index: 39;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.drawer-close.is-checked {
  display: block;
  opacity: 0.4;
}

.drawer-icon {
  display: none;
  position: relative;
  z-index: 41;
  text-align: center;
  cursor: pointer;
  background-color: #231815;
  border: none;
}

@media (max-width: 767px) {
  .drawer-icon {
    display: inline-block;
    height: 64px;
    width: 64px;
  }
}

.drawer-icon:focus {
  outline: none;
}

.drawer-icon::after {
  content: "MENU";
  font-size: 1.4rem;
  display: block;
  color: #fff;
  text-align: center;
  line-height: 1;
  margin-top: 4px;
}

@media (max-width: 767px) {
  .drawer-icon::after {
    font-size: 1rem;
  }
}

.drawer-icon.is-checked::after {
  content: "CLOSE";
  /*       color: #fff; */
}

.drawer-icon.is-checked + body {
  overflow: hidden;
}

.drawer-bars {
  display: inline-block;
  width: 30px;
  height: 14px;
  position: relative;
  vertical-align: bottom;
}

@media (max-width: 767px) {
  .drawer-bars {
    width: 30px;
    height: 18px;
  }
}

.drawer-bars:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  content: "";
  z-index: -1;
  border-radius: 50%;
}

.drawer-bar {
  position: absolute;
  right: 0;
  left: 0;
  border-radius: 0px;
  display: block;
  background: #fff;
  width: 100%;
  height: 2px;
  transition: all 0.3s linear 0s;
}

.drawer-bar:nth-of-type(1) {
  top: 0;
}

.drawer-bar:nth-of-type(2) {
  top: 6px;
  width: 60%;
}

@media (max-width: 767px) {
  .drawer-bar:nth-of-type(2) {
    top: 8px;
  }
}

.drawer-bar:nth-of-type(3) {
  top: 12px;
}

@media (max-width: 767px) {
  .drawer-bar:nth-of-type(3) {
    top: 16px;
  }
}

.is-checked .drawer-bar:nth-of-type(1) {
  top: 5px;
  transform: rotate(-45deg);
}

.is-checked .drawer-bar:nth-of-type(2) {
  background: transparent;
}

.is-checked .drawer-bar:nth-of-type(3) {
  top: 5px;
  transform: rotate(45deg);
}

.drawer-content {
  height: 100%;
}

@media (max-width: 767px) {
  .drawer-content {
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    /*     transform: translateX(105%); */
    width: 100vw;
    max-width: 100%;
    height: 100%;
    background: #9cd4cc;
    transition: all 0.5s ease 0s;
    padding: 0;
    text-align: left;
    color: #fff;
    min-width: 1180px;
  }
  .drawer-content.is-checked {
    /*       transform: translateX(0); */
    top: 0px;
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 767px) {
  .drawer-content {
    min-width: unset;
  }
}

@media (max-width: 767px) {
  .drawer-content--left {
    transform: translateX(-105%);
    left: 0;
    right: auto;
  }
}

@media (max-width: 767px) {
  .drawer-content--op {
    top: 0px;
    bottom: auto;
    height: 100vh;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-width: auto;
    opacity: 0;
    visibility: hidden;
  }
}

.drawer-content--top {
  /*   padding: 0; */
}

@media (max-width: 767px) {
  .drawer-content--top {
    transform: translateY(-105%);
    top: 0px;
    bottom: auto;
    height: 100vh;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-width: auto;
  }
}

.drawer-bg {
  height: 100%;
}

.drawer-bg.is-checked {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  transition: 0.4s;
  background: rgba(0, 0, 0, 0.4);
}

.drawer-box {
  padding-top: 100px;
}

@media (max-width: 767px) {
  .drawer-box {
    padding-top: 80px;
  }
}

.g-nav {
  height: 100%;
}

@media (max-width: 767px) {
  .g-nav {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 247px;
    height: auto;
  }
}

.g-nav-list {
  display: flex;
  height: 100%;
}

@media (max-width: 767px) {
  .g-nav-list {
    flex-direction: column;
  }
}

.g-nav-item {
  position: relative;
  height: 100%;
}

.g-nav-item + li {
  margin-left: 48px;
}

@media (max-width: 767px) {
  .g-nav-item + li {
    margin: 8px 0 0;
  }
}

.g-nav-item-link {
  width: 100%;
  height: 100%;
  transition: 0.3s;
  font-size: 1.8rem;
  text-align: center;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.g-nav-item-link:hover {
  opacity: 0.7;
}

.g-nav-item-link:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  content: "";
  transition: 0.3s;
}

@media (max-width: 767px) {
  .g-nav-item-link {
    color: #3d3d3d;
    font-size: 1.6rem;
    padding: 16px 0;
    display: inline-block;
    text-align: left;
    /*     &:before {
      top: 50%;
      right: 0%;
      transform: translateY(-50%);
      background: url(../images/common/drawer-arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 25px;
      height: 25px;
      left: auto;
    } */
  }
}

.breadcrumb {
  margin-top: 16px;
}

.breadcrumb-contents {
  display: flex;
  align-items: center;
  height: 100%;
}

.breadcrumb-contents a {
  height: 100%;
  transition: 0.3s;
}

.breadcrumb-contents a.home:hover {
  opacity: 0.7;
}

.breadspan {
  display: inline-block;
  margin: 0 12px;
  width: 25px;
  height: 25px;
}

.breadspan + span {
  color: #858585;
}

#dialog {
  background: rgba(0, 0, 0, 0.2);
  height: 100vh;
  position: fixed;
  top: 0;
  transition: 0.3s;
  width: 100vw;
  z-index: 9999999999;
  visibility: hidden;
  opacity: 0;
}

#dialog .js-active {
  visibility: visible;
  opacity: 1;
}

.dialog-box {
  background: linear-gradient(90deg, #6b3dea 0%, #9781e3 100%);
  box-shadow: 0px 2px 5px rgba(117, 75, 237, 0.25);
  left: 50%;
  padding: 30px 20px;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  z-index: 9999999999;
}

@media (max-width: 767px) {
  #dialog .dialog-box {
    min-width: 250px;
  }
}

#dialog .dialog-text {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 20px;
  color: #fff;
}

@media (max-width: 1014px) {
  #dialog .dialog-text {
    font-size: 1.6rem;
    line-height: 1.6;
  }
}

#dialog button {
  cursor: pointer;
  border-radius: 100px;
  display: block;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 100%;
  width: 100%;
  transition: all 0.3s;
  box-shadow: 0px 2px 5px rgba(117, 75, 237, 0.25);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #754bed;
  border: 1px solid #754bed;
  background: #fff;
  margin: 40px auto 0;
  width: 216px;
  height: 57px;
}

#dialog button:hover {
  background: #754bed;
  border: 1px solid #fff;
  color: #fff;
}

/* =============================================================================


     footer


============================================================================= */
/* **************************************


     sub-footer


***************************************/
footer {
  padding: 80px 0 40px;
  text-align: left;
  background: #9cd4cc;
}

@media (max-width: 767px) {
  footer {
    padding: 60px 0 24px;
  }
}

.footer-contents {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .footer-contents {
    flex-direction: column;
  }
}

.footer-info {
  margin-top: 24px;
}

.footer-info-txt {
  font-size: 1.4rem;
}

.footer-logo {
  width: 113px;
}

@media (max-width: 767px) {
  .footer-logo {
    width: 80px;
  }
}

.footer-list {
  margin-left: 120px;
}

@media (max-width: 767px) {
  .footer-list {
    margin-top: 24px;
    max-width: 195px;
  }
}

@media (max-width: 767px) and (max-width: 767px) {
  .footer-list {
    margin-left: 0;
    max-width: 100%;
  }
}

.footer-item {
  font-size: 1.6rem;
}

.footer-item + li {
  margin-top: 8px;
}

@media (max-width: 767px) {
  .footer-item {
    font-size: 1.4rem;
  }
}

.footer-link {
  transition: 0.2s;
  position: relative;
  padding-left: 1em;
}

.footer-link:before {
  content: ">";
}

.footer-link:hover {
  opacity: 0.7;
}

.copy {
  font-size: 1.4rem;
  margin-top: 100px;
  text-align: center;
}

@media (max-width: 767px) {
  .copy {
    margin-top: 40px;
    text-align: center;
    font-size: 1rem;
  }
}

.floating {
  transition: 0.2s;
  opacity: 0;
  visibility: hidden;
}

.floating a {
  width: 113px;
  height: 113px;
  transition: 0.2s;
  z-index: 10;
  right: 0;
  bottom: 60px;
  position: fixed;
  background-image: url(../images/common/floating-icon.svg);
  background-size: contain;
  background-position-x: center;
}

.floating a:hover {
  opacity: 0.7;
}

@media (max-width: 767px) {
  .floating a {
    width: 60px;
    height: 60px;
    transition: 0.2s;
    z-index: 10;
    right: 0;
    bottom: 30px;
  }
}

[data-scroll="true"] .floating {
  opacity: 1;
  visibility: visible;
}

/* =============================================================================


     header


============================================================================= */
header {
  position: relative;
  z-index: 20;
  min-width: 1228px;
  height: 132px;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  transition: 0.2s;
  box-shadow: 0px 0px 10px rgba(51, 51, 51, 0.3);
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 767px) {
  header {
    min-width: unset;
    height: 64px;
  }
}

header .inner._header {
  height: 100%;
}

@media screen and (max-width: 1199px) {
  header .inner._header {
    width: 100%;
  }
}

@media (max-width: 1228px) {
  header .inner._header {
    max-width: 1200px;
  }
}

.header-contents {
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: 0.2s;
}

.header-contents._index {
  display: none;
}

@media (max-width: 767px) {
  .header-contents._index {
    display: flex;
  }
}

.header-logo {
  display: inline-block;
}

.header-logo a {
  transition: 0.2s;
  display: inline-block;
  position: relative;
}

@media (max-width: 767px) {
  .header-logo a {
    width: 130px;
  }
}

.header-logo a:hover {
  opacity: 0.7;
}

.header-nav-container {
  height: 100%;
  margin-left: 240px;
}

@media (max-width: 767px) {
  .header-nav-container {
    margin-left: 0;
    height: auto;
  }
}

.header-btn-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .header-btn-list {
    display: none;
  }
}

@media (max-width: 767px) {
  .header-btn-list.sp-dis {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
}

.header-btn-item + .header-btn-item {
  margin-left: 24px;
}

@media (max-width: 767px) {
  .header-btn-item + .header-btn-item {
    margin: 24px auto 0;
  }
}

.header-mail {
  position: fixed;
  right: 0;
  top: 0;
  width: 132px;
  height: 132px;
  background: #9cd4cc;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

.header-mail:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.header-mail:hover {
  opacity: 0.7;
}

.header-mail img {
  width: 60px;
}

@media (max-width: 767px) {
  .header-mail {
    height: 64px;
    width: 64px;
  }
  .header-mail img {
    width: 30px;
  }
}

/* =============================================================================


    sf


============================================================================= */
.sf {
  padding: 120px 0;
  position: relative;
  overflow: hidden;
  margin-top: 120px;
}

@media (max-width: 767px) {
  .sf {
    padding: 80px 0;
    margin-top: 0px;
  }
}

.sf-ttl {
  text-align: left;
  font-size: 12rem;
  font-weight: bold;
  font-family: Avenir, acumin-pro, sans-serif;
  width: 100%;
  z-index: 2;
  position: absolute;
  top: -1.25em;
  left: 50%;
  transform: translateX(-50%);
}

.sf-ttl span {
  color: #9cd4cc;
}

@media (max-width: 767px) {
  .sf-ttl {
    line-height: 1.2;
    font-size: 5rem;
    position: relative;
    top: auto;
    transform: none;
    left: auto;
  }
}

.sf-contents {
  text-align: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.sf-contents:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100%;
  background: #9cd4cc;
  z-index: -1;
  min-width: 1228px;
}

@media (max-width: 767px) {
  .sf-contents:before {
    min-width: unset;
  }
}

@media (max-width: 767px) {
  .sf-contents {
    flex-direction: column;
  }
}

.sf-content {
  width: 50%;
}

@media (max-width: 767px) {
  .sf-content {
    width: 100%;
  }
}

.sf-content-ttl {
  font-size: 6.5rem;
  font-weight: 500;
  padding: 24px 0;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 767px) {
  .sf-content-ttl {
    font-size: 2.4rem;
    justify-content: center;
  }
}

.sf-content-txtbox {
  position: relative;
  text-align: left;
  color: #fff;
  padding: 24px 0;
  padding-right: 24px;
}

.sf-content-txtbox:before {
  background-color: #000;
  content: "";
  z-index: -1;
  width: 50vw;
  right: 0;
  top: 0;
  height: 100%;
  position: absolute;
  min-width: calc(1228px / 2);
}

@media (max-width: 767px) {
  .sf-content-txtbox:before {
    width: 100vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: unset;
  }
}

@media (max-width: 767px) {
  .sf-content-txtbox {
    padding: 24px 0;
  }
}

.sf-content-ttl,
.sf-btn-box {
  min-height: 230px;
}

@media (max-width: 767px) {
  .sf-content-ttl,
  .sf-btn-box {
    min-height: unset;
    height: auto;
  }
}

.sf-content-txtbox,
.sf-btn-img {
  height: 440px;
}

@media (max-width: 767px) {
  .sf-content-txtbox,
  .sf-btn-img {
    height: auto;
  }
}

.sf-txtbox-txt {
  font-size: 1.8rem;
  line-height: 2.77778;
}

@media (max-width: 767px) {
  .sf-txtbox-txt {
    font-size: 1.4rem;
  }
}

.sf-txtbox-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
}

.sf-txtbox-img {
  width: 30%;
  margin-right: 24px;
}

.sf-txtbox-img img {
  width: 100%;
}

@media (max-width: 767px) {
  .sf-txtbox-img {
    width: 10%;
    margin-right: 24px;
  }
}

.sf-txtbox-list {
  width: calc(70% - 24px);
}

@media (max-width: 767px) {
  .sf-txtbox-list {
    width: calc(90% - 24px);
  }
}

.sf-txtbox-head {
  color: #9cd4cc;
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (max-width: 767px) {
  .sf-txtbox-head {
    font-size: 2.4rem;
  }
}

.sf-txtbox-item {
  margin-top: 16px;
  font-size: 1.8rem;
}

.sf-txtbox-item + li {
  margin-top: 8px;
}

@media (max-width: 767px) {
  .sf-txtbox-item {
    font-size: 1.4rem;
  }
}

.sf-btn-content {
  width: 50%;
}

@media (max-width: 767px) {
  .sf-btn-content {
    width: 100%;
  }
}

.sf-btn-img {
  width: 50vw;
  min-width: calc(1228px / 2);
}

.sf-btn-img img {
  object-fit: cover;
  object-position: top;
  font-family: "object-fit:cover;object-position: top;";
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .sf-btn-img {
    width: calc(100% + 32px);
    left: -16px;
    margin: auto;
    position: relative;
    min-width: unset;
  }
}

.sf-btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .sf-btn-box {
    padding: 24px 0;
  }
}

.sf-btn {
  border: 1px solid #000;
  font-size: 3rem;
  transition: 0.2s;
  width: 400px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sf-btn:hover {
  opacity: 0.7;
}

.sf-btn img {
  width: 40px;
  margin-right: 8px;
  display: block;
}

@media (max-width: 767px) {
  .sf-btn {
    font-size: 2rem;
    width: 280px;
    height: 50px;
  }
  .sf-btn img {
    width: 30px;
  }
}

.pc-dis {
  display: none;
}

@media (max-width: 1366px) {
  .pc-dis {
    display: block;
  }
}

.tab-none {
  display: block;
}

@media (max-width: 1228px) {
  .tab-none {
    display: none;
  }
}

.tab-dis {
  display: none;
}

@media (max-width: 1228px) {
  .tab-dis {
    display: block;
  }
}

.sp-none {
  display: block;
}

@media (max-width: 767px) {
  .sp-none {
    display: none;
  }
}

.sp-dis {
  display: none;
}

@media (max-width: 767px) {
  .sp-dis {
    display: block;
  }
}
